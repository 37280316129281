const accountsAPIURL = process.env.REACT_APP_MAIN_ACCOUNTS_HOST
    ?  process.env.REACT_APP_MAIN_ACCOUNTS_HOST
    : window.location.host.toString().indexOf("test") != -1
    ? 'https://accounts.test.omnigroup.com'
    : window.location.host.toString().indexOf("stage") != -1
    ? 'https://accounts.stage.omnigroup.com'
    : 'https://accounts.omnigroup.com';

const purchaseAPIURL = process.env.REACT_APP_MAIN_PURCHASE_HOST
    ? process.env.REACT_APP_MAIN_PURCHASE_HOST
    : window.location.host.toString().indexOf("test") != -1
    ? 'https://purchase.test.omnigroup.com'
    : window.location.host.toString().indexOf("stage") != -1
    ? 'https://purchase.stage.omnigroup.com'
    : 'https://purchase.omnigroup.com';

const taxserviceURL = process.env.REACT_APP_MAIN_TAXSERVICE_HOST
    ? process.env.REACT_APP_MAIN_TAXSERVICE_HOST
    : window.location.host.toString().indexOf("test") != -1 || window.location.host.toString().indexOf("stage") != -1
    ? 'https://taxservice.stage.omnigroup.com'
    : 'https://taxservice.stage.omnigroup.com';

const storeURL = process.env.REACT_APP_MAIN_PURCHASE_HOST || window.location.host.toString().indexOf("test") != -1
    ? 'https://store.test.omnigroup.com'
    : window.location.host.toString().indexOf("stage") != -1
    ? 'https://store.stage.omnigroup.com'
    : 'https://store.omnigroup.com';

const initialState = {
    accountsAPIURL, 
    purchaseAPIURL,
    storeURL,
    taxserviceURL,
};

const apiURLs = (state = initialState) => {
    return state;
}

export default apiURLs;
